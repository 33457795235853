<div
    class="form-group"
    [class.no-label]="!label"
    [class.clr-error]="formFieldControl?.formControlName?.invalid"
>
    <label *ngIf="label" [for]="for" class="">
        {{ label }}
    </label>
    <div *ngIf="tooltip" class="tooltip-text">
        {{ tooltip }}
    </div>
    <div
        class="input-row"
        [class.has-toggle]="readOnlyToggle"
        [class.invalid]="formFieldControl?.touched && !formFieldControl?.valid"
    >
        <ng-content></ng-content>
        <button
            *ngIf="readOnlyToggle"
            type="button"
            [disabled]="!isReadOnly"
            [title]="'common.edit-field' | translate"
            class="btn btn-icon edit-button"
            (click)="setReadOnly(false)"
        >
            <clr-icon shape="edit"></clr-icon>
        </button>
    </div>
    <div class="error-message" *ngIf="getErrorMessage()">{{ getErrorMessage() }}</div>
</div>
